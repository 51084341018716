import { TypeAnimation } from 'react-type-animation';
import "./App.css";

function App() {
  return (
    <div className="container">
      <div className='containerBg'>
      <div className="title-text">
      <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'Hi,',
        1000, // wait 1s before replacing "Mice" with "Hamsters"
        'I\'am Akalanka Wijesinghe',
        3000,
        'Welcome to my personal space',
        8000,
      ]}
      wrapper="span"
      speed={20}
      style={{ fontSize: '4em', display: 'inline-block',fontWeight:'600' }}
      repeat={Infinity}
    />
      </div>
      </div>
     
    </div>
  );
}

export default App;
